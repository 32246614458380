export class UserStorageModel {

    user_name: string;
    email: string;
    token: string;

    constructor(user_name: string, email: string, token: string) {
      this.user_name = user_name;
      this.email = email;
      this.token = token;
    }
}
