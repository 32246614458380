export class StateModel {

  id: number;
  translation: string;
  name: string;
  flag: string;

  constructor(id: number, translation: string, name: string, flag: string) {
    this.id = id;
    this.translation = translation;
    this.name = name;
    this.flag = flag;
  }

}
