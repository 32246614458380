import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseModel } from '@app-models/response.model';
import { CONSTANTS } from '@app-utils/constants';
import { Utils } from '@app-utils/utils';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IProduct } from 'src/app/interfaces/product.interface';

@Injectable({
  providedIn: 'root'
})

export class StoreService {

  public positionArrTemplate: number;

  activeModalTemplateSub$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  activeModalTemplate$: Observable<boolean> = this.activeModalTemplateSub$.asObservable();

  productLot = new Subject<IProduct>();
  productLot$ = this.productLot.asObservable();

  constructor(
    private httpClient: HttpClient,
    private utils: Utils
    ) {}


  /**
   * Obtiene los productos por la categoria de productos
   * @param product informacion de la cata
   */
   getProductsByCategoryId(idCategory: string): Observable<any> {
    const options = this.utils.createHeader(CONSTANTS.REQUEST_METHOD.GET);
    return this.httpClient.get<ResponseModel>(CONSTANTS.URL.GET_PRODUCTS_CATEGORY_ID + idCategory, options);
  }

  /**
   * Obtiene los productos por la categoria de productos
   * @param product informacion de la cata
   */
   getProductsByCategory(): Observable<any> {
    const options = this.utils.createHeader(CONSTANTS.REQUEST_METHOD.GET);
    return this.httpClient.get<ResponseModel>(CONSTANTS.URL.GET_PRODUCTS_CATEGORY, options);
  }

  /**
   * Obtiene los productos por la categoria de productos
   * @param product informacion de la cata
   */
   getProductsByIdentifier(identifier: string): Observable<any> {
    const options = this.utils.createHeader(CONSTANTS.REQUEST_METHOD.GET);
    return this.httpClient.get<ResponseModel>(CONSTANTS.URL.GET_PRODUCTS_IDENTIFIER + identifier, options);
  }

}
