import { Injectable } from '@angular/core';
import { CheckModel } from '@app-models/check.model';

@Injectable({
  providedIn: 'root'
})

export class CookiesService {

  activateCookies(checkboxCookies: CheckModel) {

  }

  cookiesTechnical() {

  }

  cookiesPreferences() {

  }

  cookiesAnalysis() {
    // google analytics

  }

  cookiesAdvertising() {

  }

}
