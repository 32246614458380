import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ICart } from '@app-components/shopping-cart/interfaces/shopping-cart.interface';
import { StoreService } from '@app-components/store/service/store.service';
import { CartService } from '@app-services/cart.service';
import { IProduct } from 'src/app/interfaces/product.interface';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})

export class ShoppingCartComponent implements OnInit {


  form: FormGroup = new FormGroup(
    {
      cart: new FormControl()
    }
  );

  cart: ICart;
  closeModal = true;
  activeModalTemplate = false;
  quantityProduct = 0;
  valueQuantityField: number;

  arrOil: IProduct[];
  arrOilSeleted: IProduct[];
  seletedProductText: string = 'El número de productos elegidos para este lote es';
  seletedProducts: number = 0;
  seletedCostText: string = 'Coste total del lote';
  selectedPriceCost: number = 0;

  constructor(private router: Router, private cartService: CartService, private templateStoreService: StoreService) {
    this.cartService.itemVar$.subscribe((data: ICart) => {
      if(data !== undefined && data !== null) {
        console.log(`Log es del carrito de compra por que el producto ha cambiado`);

        this.cart = data
      }
    });

  }

  ngOnInit(): void {
    this.cart = this.cartService.initialize();
    console.log(`Inicializamos el carrito desde el oninit: ${this.cart}`);

  }

  clear() {
    this.cartService.clear();
  }

  clearItem(product: IProduct) {
    this.manageProductUnitInfo(0, product)

  }


  changeValue($event, product) {

    console.log(`Este es el valor del input ${$event}, y este es el product ${product}`);

    product.quantity = $event;
    this.cartService.manageProduct(product)

  }

  manageProductUnitInfo(quantity: number, product: IProduct) {
    console.log(`entramos en manageProductUnitInfo`);

    product.quantity = quantity;
    this.cartService.manageProduct(product)
  }

  proccess() {
    this.router.navigate(['/checkout']);
    this.closeNav();
  }

  closeNav() {
    this.cartService.close();
  }

  public changeModelTemplate(product?: IProduct) {

    console.log(`Llamando al metodo changeModelTemplate`);

    this.templateStoreService.activeModalTemplateSub$.next(true);
    if (product){

      setTimeout(() => this.templateStoreService.productLot.next(product), 0)
    }
  }

  public changeModelTemplate2() {
    this.closeModal = !this.closeModal;
    if (this.closeModal) {

      this.templateStoreService.activeModalTemplateSub$.next(false);
      console.log('Se pone la modal a false');
    }
  }

  public changeOilButton(index: number, product: any, oilStatus: boolean) {

    this.calculateTotal(index, oilStatus, product)

  }


  calculateTotal(index: number, oilStatus: boolean, product: IProduct) {


    if (  oilStatus === true) {

      if (!product.quantity || product.quantity === 0) {
        product.quantity = 1
      } else {

        product.quantity = product.quantity + 1;
      }

      this.manageProductUnitInfo(product.quantity, product);

      this.cartService.itemsCart.next({_id: product._id, quantity: product.quantity})

    } else {

      if (!product.quantity) {
        product.quantity = 0
      } else {

        product.quantity = product.quantity - 1;
      }

      this.manageProductUnitInfo(product.quantity, product);

      this.cartService.itemsCart.next({_id: product._id, quantity: product.quantity})
    }
  }

}
