import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {  } from '@angular/google-maps';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { ShoppingCartRoutingModule } from './shopping-cart-routing.module';
import { ShoppingCartComponent } from './components/shopping-cart/shopping-cart.component';
import { Utils } from '@app-utils/utils';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ShoppingCartRoutingModule,
    NgxSpinnerModule,
    TranslateModule,
  ],
  declarations: [
    ShoppingCartComponent
  ],
  entryComponents: [
  ],
  exports: [ShoppingCartComponent],
  providers: [
    Utils
  ]
})
export class ShoppingCartModule { }
