<nav class="navbar navbar-expand-lg navbar-dark background-container-nav">
  <!--
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul id="ulMenu" class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link home" (click)="goToPage('inicio')">Inicio</a>
      </li>
      <li class="nav-item">
        <a class="nav-link about-us" (click)="goToPage('nosotros')">Nosotros</a>
      </li>
      <li class="nav-item">
        <a class="nav-link services" (click)="goToPage('cursos')">Cursos</a>
      </li>
      <li class="nav-item">
        <a class="nav-link faqs" (click)="goToPage('catas')">Catas</a>
      </li>
      <li class="nav-item">
        <a class="nav-link contact" (click)="goToPage('contacto')">Contacto</a>
      </li>
    </ul>
  </div>
-->


  <div class="navbar-brand" (click)="goToPage('home')">
    <!-- Texto-->
    <img width="111" height="80" src="/assets/images/app/header/logo-150x80.png" alt="logo">
  </div>

  <div class="aaaaaaa">
    <button id="menu" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-label="Toggle navigation">
      <!-- <span class="navbar-toggler-icon"></span> -->
      <span class="material-icons">
        menu
      </span>
    </button>
    <div class="buttons-menu">
      <div class="container-language">
        <form class="container-form" [formGroup]="formularioPrueba">
          <!-- <mat-form-field class="form-field-language">
              <mat-select [panelClass]="'language-select'" formControlName="combo" [(ngModel)]="selected.id">
                <mat-select-trigger>
                  <div class="container-view-select">
                    <img width="25" height="25" class="img-flag" alt="{{selected.name}}"
                      src="{{'/assets/images/app/flags/' + selected.flag}}" />
                    <div class="text-color-language-selected">{{selected.name}}</div>
                  </div>
                </mat-select-trigger>
                <mat-option *ngFor="let option of options, let i = index" [value]="option.id">
                  <img style="vertical-align:middle;" alt="{{option.name}}" src="{{'/assets/images/app/flags/' + option.flag}}"
                    width="25" height="25" />
                  {{option.name}}
                </mat-option>
              </mat-select>
            </mat-form-field> -->
        </form>
      </div>
    </div>
  </div>

  <div class="shopping-cart-collapse">
    <a class="nav-item" (click)="open()"><i class="fa fa-shopping-cart"></i>
      <span class="badge badge-success notification">{{cartItemsTotal}}</span>
    </a>

  </div>


  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul id="ulMenu menu-right" class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link inicio" (click)="goToPage('inicio')">Inicio</a>
      </li>
      <li class="nav-item">
        <a class="nav-link catas" (click)="goToPage('catas')">Catas</a>
      </li>

      <li ngbDropdown class="nav-item dropdown">
        <a ngbDropdownToggle class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Oleotecas</a>

        <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item oleotecas" (click)="goToPage('oleotecas/jaen')">Jaén</a>
        </div>

      </li>

      <li class="nav-item">
        <a class="nav-link contacto" (click)="goToPage('contacto')">Contacto</a>
      </li>

      <li ngbDropdown class="nav-item dropdown">
        <a ngbDropdownToggle class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Tienda</a>

        <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="navbarDropdown">

          <!--           <a class="dropdown-item tienda" (click)="goToPage('tienda/packs-aove-seleccion')">Packs AOVE selección</a>
 --> <a class="dropdown-item tienda" (click)="goToPage('tienda/aceites')">Aceites</a>
          <a class="dropdown-item tienda" (click)="goToPage('tienda/souvenir')">Souvenir</a>

        </div>

      </li>
      <li class="nav-item shopping-cart-not-collapse">
        <a class="nav-item" (click)="open()"><i class="fa fa-shopping-cart"></i>
          <span class="badge badge-success notification">{{cartItemsTotal}}</span>
        </a>

      </li>
    </ul>
  </div>
  <div class="session-icons">
    <ul id="ulMenuBoard" class="navbar-nav" [ngClass]="isHidden2 ? 'isHiddenField2' : ''">
      <li class="nav-item dropdown direction-image">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <img src="{{imageUrl}}" width="40" height="40" class="rounded-circle pointer"> {{(userData && userData.user_name !== undefined) ? userData.user_name : ''}}
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item management"
            [ngClass]="userData && (userData.role.role_name === 'superadmin' || userData.role.role_name === 'admin') ? '' : 'isNotAdmin'"
            (click)="goToManagement('management')">
            Administración
          </a>
          <a class="dropdown-item dashboard" (click)="goToPage('dashboard')">Tablero</a>
          <a class="dropdown-item user-administration" (click)="goToPageDashboard('user-administration')">Gestionar
            Usuario</a>
        </div>
      </li>
    </ul>
    <img width="40" height="40" (click)="cerrarSesion()" [ngClass]="isHidden2 ? 'isHiddenField2' : ''"
      src="/assets/images/app/navigation-icons/logout-icon.png" alt="Icono logout" class="icon pointer">
    <!--
      <img width="40" height="40" (click)="iniciarSesion()" [ngClass]="isHidden2 ? '' : 'isHiddenField2'"
        src="/assets/images/app/navigation-icons/login-icon.png" alt="Icono login" class="icon pointer">
        -->
  </div>
</nav>
