import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StateModel } from '@app-models/state.model';
import * as uuid from 'uuid';

@Injectable()
export class Utils {

  /**
   * Crea una cabecera sin token
   *
   * @param method metodo de la petición
   */
  createHeader(method: string): any {

    let header = {};

    return header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=UTF-8',
        'Access-Control-Allow-Origin': 'http://localhost:8080',
        'Access-Control-Allow-Methods': method,
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      })
    };
  }

  /**
   * Crea una cabecera con token
   *
   * @param method metodo de la petición
   * @param token token del usuario
   */
  createHeaderWithToken(method: string, token: string): any {

    let header = {};

    return header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Authorization': token,
        'Access-Control-Allow-Origin': 'http://localhost:8080',
        'Access-Control-Allow-Methods': method,
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      })
    };
  }

  /**
   * Crea una cabecera con token para enviar imagen
   *
   * @param token token del usuario
   */
  createHeaderWithTokenForImage(token: string): any {
    const header = {};
    return {
      headers: new HttpHeaders({
        'Authorization': token,
      }),
    };
  }

  /**
   * Devuelve el objeto de las banderas de los paises
   */
  getFlags(): StateModel[] {
    const flags: StateModel[] = [
      {
        id: 1,
        translation: 'es-ES',
        name: 'Commons.Language.Spain',
        flag: 'spain.png'
      },
      {
        id: 2,
        translation: 'en-EN',
        name: 'Commons.Language.UnitedKingdom',
        flag: 'united-kingdom.png'
      },
      {
        id: 3,
        translation: 'fr-FR',
        name: 'Commons.Language.France',
        flag: 'france.png'
      },
      {
        id: 4,
        translation: 'pt-PT',
        name: 'Commons.Language.Portugal',
        flag: 'portugal.png'
      },
      {
        id: 5,
        translation: 'it-IT',
        name: 'Commons.Language.Italian',
        flag: 'italy.png'
      }
    ];

    return flags;
  }

  /**
   * Crea una copia de un array y de los objetos que contiene
   * @param arrayToCopy array a copiar
   * @returns nuevo array
   */
  copyArrayWithObject(arrayToCopy: any[]): any[] {
    const newArray = [];
    for ( let i = 0, l = arrayToCopy.length; i < l; i++ ) {
      newArray[ i ] = JSON.parse(JSON.stringify(arrayToCopy[ i ]));
    }
    return newArray;
  }

  /**
   * Pone en mayúscula la primera letra de una palabra
   * @param word palabra
   * @returns Palabra con la primera letra en mayúscula
   */
  capitalizeFirstLetter(word: string): string {
    let wordWithCapitalize = '';
    if(word !== undefined && word.length > 0) {
      wordWithCapitalize = word.charAt(0).toUpperCase() + word.slice(1);
    }
    return wordWithCapitalize;
  }

  /**
   * Elimina los valores falsos de un array (0, null, false)
   * @param array array a limpiar de valores false
   * @returns array sin valores false
   */
  removeFalseValuesFromArray(array: any[]): any[] {
    let newArray = [];
    if(array.length > 0) {
      newArray = array.filter(Boolean);
    }
    return newArray;
  }

  /**
   * Obtiene el valor de una propiedad de un objeto dando
   * el final del nombre de una pripiedad de dicho objeto
   * @param object objeto
   * @param endProperty final del string a buscar
   * @returns valor de la propiedad a buscar
   */
  getValueOfEndNameKeyOfObject(object: any, endProperty: string): any {
    let valueKey: any = '';
    if(object !== undefined && object !== null && endProperty !== '') {
      Object.keys(object).forEach((keyName: any) => {
        if (keyName.indexOf(endProperty) !== -1) {
          valueKey = object[keyName];
        }
      });
    }
    return valueKey;
  }

  generarGUID() {

    const guid = uuid.v4();

    return guid;
}

}
