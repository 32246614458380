export class EnvironmentConstant {

    static DEVELOP: string = 'development';
    static DEV_PROTOCOL: string = 'http://';
    static DEV_HOST: string = 'localhost:';
    static DEV_FRONT_PORT: number = 8080;
    static DEV_PORT: number = 5000;


    static PRODUCTION: string = 'production';
    static PROD_PROTOCOL: string = 'https://';
    static PROD_BACK_HOST: string = 'aove-panel-back.herokuapp.com';
    static PROD_FRONT_HOST: string = 'aoveseleccionclub.com';
    static PROD_BACK_PORT: string | undefined = process.env.PORT;

}
