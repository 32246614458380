import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CookiesService } from '@app-services/cookies.service';

@Injectable()
export class CookiesInterceptor implements HttpInterceptor {
  constructor(private cookiesService: CookiesService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('esta es mi request: ' + request);
    return next.handle(request);
  }
}
