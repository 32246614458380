import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'inicio',
    loadChildren: () =>
      import('./components/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'catalogo/aceites',
    loadChildren: () =>
      import('./components/catalog/catalog.module').then((m) => m.CatalogModule),
  },
  {
    path: 'catalogo/cosmeticos',
    loadChildren: () =>
      import('./components/catalog/catalog.module').then((m) => m.CatalogModule),
  },
  {
    path: 'catalogo/gourmet',
    loadChildren: () =>
      import('./components/catalog/catalog.module').then((m) => m.CatalogModule),
  },
  {
    path: 'catalogo/souvenirs',
    loadChildren: () =>
      import('./components/catalog/catalog.module').then((m) => m.CatalogModule),
  },
  {
    path: 'catas',
    loadChildren: () =>
      import('./components/tasting/tasting.module').then((m) => m.TastingModule),
  },
  {
    path: 'contacto',
    loadChildren: () =>
      import('./components/contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: 'cookies-policy',
    loadChildren: () =>
      import('./components/cookies-policy/cookies-policy.module').then((m) => m.CookiesPolicyModule),
  },
  {
    path: 'politica-de-privacidad',
    loadChildren: () =>
      import('./components/politica-de-privacidad/politica-de-privacidad.module').then((m) => m.PrivacyPolicyModule),
  },
  {
    path: 'legal-notice',
    loadChildren: () =>
      import('./components/legal-notice/legal-notice.module').then((m) => m.LegalNoticeModule),
  },
  {
    path: 'confirmacion-cata/:tasting_token',
    loadChildren: () =>
      import('./components/confirmacion-cata/confirmacion-cata.module').then((m) => m.ConfirmacionCataModule),
  },/*
  {
    path: 'oleotecas/granada-cartel',
    loadChildren: () =>
      import('./components/oleoteca/oleoteca.module').then((m) => m.OleotecaModule),
  }, */
  {
    path: 'oleotecas/jaen',
    loadChildren: () =>
      import('./components/oleoteca/oleoteca.module').then((m) => m.OleotecaModule),
  },/*
  {
    path: 'tienda/packs-aove-seleccion',
    loadChildren: () =>
      import('./components/store/store.module').then((m) => m.StoreModule),
  }, */
  {
    path: 'tienda/aceites',
    loadChildren: () =>
      import('./components/store/store.module').then((m) => m.StoreModule),
  },
  {
    path: 'tienda/souvenir',
    loadChildren: () =>
      import('./components/store/store.module').then((m) => m.StoreModule),
  },
  {
    path: 'checkout',
    loadChildren: () =>
      import('./components/checkout/checkout.module').then((m) => m.CheckoutModule),
  },
  {
    path: 'producto-detalle/:id',
    loadChildren: () =>
      import('./components/product-detail/product-detail.module').then((m) => m.ProductDetailModule),
  },
  {
    path: 'accept-payment',
    loadChildren: () =>
      import('./components/accept-payment/accept-payment.module').then((m) => m.AcceptPaymentModule),
  },
  {
    path: 'cancel-payment',
    loadChildren: () =>
      import('./components/cancel-payment/cancel-payment.module').then((m) => m.CancelPaymentModule),
  },
  {
    path: 'sign-in',
    loadChildren: () =>
      import('./components/sign-in/sign-in.module').then((m) => m.SignInModule),
  },
  {
    path: 'recovery-password',
    loadChildren: () =>
      import('./components/recovery-password/recovery-password.module').then((m) => m.RecoveryPasswordModule),
  },
  {
    path: 'change-recovery-password/:guid/:email/:newPassword',
    loadChildren: () =>
      import('./components/change-recovery-password/change-recovery-password.module').then((m) => m.ChangeRecoveryPasswordModule),
  },
  {
    path: 'management',
    loadChildren: () =>
      import('./components/management/management.module').then((m) => m.ManagementModule),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./components/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'products-management/:idCategory',
    loadChildren: () =>
      import('./components/management/products-management/products-management.module').then((m) => m.ProductsManagementModule),
  },
  { path: '**', pathMatch: 'full', redirectTo: 'inicio' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
