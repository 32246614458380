import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SeparatorSectionComponent } from './commons/components/separator-section/separator-section.component';
import { MatButtonModule } from '@angular/material/button';
import { SeparatorBigNotImageComponent } from './commons/components/separator-big-not-image/separator-big-not-image.component';
import { RouterModule } from '@angular/router';
import { FooterSectionsComponent } from './commons/components/footer-sections/footer-sections.component';
import { MatIconModule } from '@angular/material/icon';
import { CheckboxComponent } from './commons/components/checkbox/checkbox.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideDirective } from '@app-directives/click-outside.directive';
import { MatSelectModule } from '@angular/material/select';
import { ComboComponent } from './commons/combo/combo.component';
import { GenericTextareaComponent } from './commons/components/textarea/textarea.component';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
/* import { ModalPackOilComponent } from './commons/components/modal-pack-oil/modal-pack-oil.component';
 */

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    TranslateModule,
    MatCheckboxModule,
    MatSelectModule,
    MatInputModule,
    MatAutocompleteModule
  ],
  declarations: [
    SeparatorSectionComponent,
    SeparatorBigNotImageComponent,
    FooterSectionsComponent,
    CheckboxComponent,
    ClickOutsideDirective,
    ComboComponent,
    GenericTextareaComponent/* ,
    ModalPackOilComponent */
  ],
  exports: [
    SeparatorSectionComponent,
    SeparatorBigNotImageComponent,
    FooterSectionsComponent,
    CheckboxComponent,
    ClickOutsideDirective,
    ComboComponent,
    GenericTextareaComponent/* ,
    ModalPackOilComponent */
  ],
  providers: []
})
export class SharedModule {}
