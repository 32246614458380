import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  public onlyLogoSubject: BehaviorSubject<boolean>;
  public onlyLogo: Observable<boolean>;

  public onlyLogoSubject2: BehaviorSubject<boolean>;
  public onlyLogo2: Observable<boolean>;

  private onlyLogoSub$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  onlyLogo$: Observable<boolean> = this.onlyLogoSub$.asObservable();

  private onlyLogo2Sub$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  onlyLogo2$: Observable<boolean> = this.onlyLogo2Sub$.asObservable();


  // controla si esta desplegado o colapsado el menu
  private isMenuCollapsedSub$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isMenuCollapsed$: Observable<boolean> = this.isMenuCollapsedSub$.asObservable();

  public imageUserSub$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  imageUser$: Observable<any> = this.imageUserSub$.asObservable();

  // private clickToggleButtonSub$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // clickToggleButton$: Observable<boolean> = this.clickToggleButtonSub$.asObservable();

  constructor() {
    //this.onlyLogoSubject = new BehaviorSubject<boolean>(true);
    //this.onlyLogo = this.onlyLogoSubject.asObservable();

    //this.onlyLogoSubject2 = new BehaviorSubject<boolean>(true);
    //this.onlyLogo2 = this.onlyLogoSubject2.asObservable();
  }

  nextValueIconsNavigationBar(value: boolean) {
    this.onlyLogoSub$.next(value);
    this.onlyLogo2Sub$.next(value);
  }
/*
  nextValueToggleButton(value: boolean) {
    this.clickToggleButtonSub$.next(value);
  }
  */

  /**
   * Cambia el estado del menu
   * @param isCollapsed esta colapsado
   */
  changeStateMenuCollapsed(isCollapsed: boolean): void {
    this.isMenuCollapsedSub$.next(isCollapsed);
  }

  /**
   * Obtiene el estado del menu
   */
  obtainStateOfMenu(): boolean {
    return this.isMenuCollapsedSub$.getValue();
  }

}
