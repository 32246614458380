import { EnvironmentConstant } from "@app-utils/environment.constant";

const supportedLanguagesList: string[] = ['it-IT', 'en-EN', 'es-ES', 'fr-FR', 'pt-PT'];
const languageIfNotAny = 'es-ES';

export const environment = {
  production: true,
  defaultLanguage: supportedLanguagesList.includes(navigator.language) ? navigator.language : languageIfNotAny,
  supportedLanguages: supportedLanguagesList
};

export class Environment {

  static PortBackEnd() {
      if (process.env.NODE_ENV === EnvironmentConstant.PRODUCTION) {

          return EnvironmentConstant.PROD_BACK_PORT;
      } else if (process.env.NODE_ENV === EnvironmentConstant.DEVELOP) {

          return EnvironmentConstant.DEV_PORT;
      }
  }

  static HostBackEnd = () => {
      if (process.env.NODE_ENV === EnvironmentConstant.PRODUCTION) {

          return EnvironmentConstant.PROD_BACK_HOST;
      } else if (process.env.NODE_ENV === EnvironmentConstant.DEVELOP) {

          return EnvironmentConstant.DEV_HOST;
      }
  }

  static PathFrontEnd() {
      if (process.env.NODE_ENV === EnvironmentConstant.PRODUCTION) {

          return EnvironmentConstant.PROD_PROTOCOL + EnvironmentConstant.PROD_FRONT_HOST
      } else if (process.env.NODE_ENV === EnvironmentConstant.DEVELOP) {

          return EnvironmentConstant.DEV_PROTOCOL + EnvironmentConstant.DEV_HOST + EnvironmentConstant.DEV_FRONT_PORT
      }
  }

  static PathBackEnd() {
      if (process.env.NODE_ENV === EnvironmentConstant.PRODUCTION) {

          return EnvironmentConstant.PROD_PROTOCOL + EnvironmentConstant.PROD_BACK_HOST
      } else if (process.env.NODE_ENV === EnvironmentConstant.DEVELOP) {

          return EnvironmentConstant.DEV_PROTOCOL + EnvironmentConstant.DEV_HOST + EnvironmentConstant.DEV_PORT
      }
  }
}
