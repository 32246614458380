<div class="main-container-main">

  <div id="mySidenav" class="sidenav">
    <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
    <h5>Carrito de la compra</h5>
    <hr>
    <div class="cart product" *ngIf="cart.total === 0">
      No tienes elementos guardados en el carrito de compra. Añádelos desde los productos con la opción de Añadir a la
      cesta
    </div>


    <div class="cart product" *ngFor="let product of cart.products; let i = index">

      <div class="container-product-button">
        <button (click)="clearItem(product)" class="btn btn-sm btn-danger">X</button>

        <div class="container-img">
          <img class="img-fluid" src="data:{{product.image.type}};base64,{{product.image.image}}" [alt]="product.title">

        </div>
      </div>

      <div class="container-detail-product">

        <div class="container-description">

          <div class="container-name-price">
            <h6> <strong>{{product.title}} {{product.format}}</strong></h6>

            <div class="container-price-total">
              <h6> <strong>Precio por unidad: </strong></h6><span class="price-product">{{product.price | number :
                '1.2-2'}} €</span>
            </div>
            <!--
            <div class="container-price-total" *ngIf="product.contentLotSeleted">
              <h6> <strong>Precio: </strong></h6><span class="price-product">{{product.price | number : '1.2-2'}}
                €</span>
            </div>
          </div> -->
            <!--
          <div *ngIf="product.contentLotSeleted" class="container-contentLot-detail">

            <ng-container *ngFor="let item of product.contentLotSeleted">

              <div class="detail-product-detail">

                <div class="container-img-productLot">
                  <img [src]="item.image" class="img-fluid" alt="item.nameProduct">
                </div>
                <p>{{item.nameProduct}}</p>

              </div>
            </ng-container>
          </div> -->

            <div class="container-price-total">

              <label for="firstName"><strong>Cantidad</strong></label>

              <div class="container-add-remove">
                <div (click)="changeOilButton(i, product, false)" class="btn removeLot">-</div>
                <h5>{{product.quantity}}</h5>
                <div (click)="changeOilButton(i, product, true)" class="btn addLot">+</div>
              </div>
            </div>

            <div class="container-price-total">
              <h6> <strong>Total: </strong></h6><span class="price-product">{{product.price * product.quantity | number
                :
                '1.2-2'}} €</span>
            </div>

          </div>
          <div class="container-buttons-product">
            <!--           <button  (click)="changeModelTemplate(product)"class="btn btn-success"  *ngIf="product.contentLotSeleted">Personalizar</button>
 -->
            <!-- <button (click)="changeModelTemplate()" class="btn btn-success" *ngIf="product.contentLotSeleted">Añadir
            lote</button>
        </div> -->

          </div>

        </div>
      </div>



    </div>

    <div class="container-total">

      <h6>Subtotal ({{cart.subTotal}})</h6>
      <h6>Total a pagar: {{cart.total | number : '1.2-2'}} €</h6>
    </div>
    <div class="container-buttons-cart">
      <button class="btn btn-success" (click)="proccess()">Tramitar Pedido</button>
      <button class="btn btn-danger" (click)="clear()">Vaciar Carrito</button>
    </div>
  </div>

  <div id="overlay"></div>
</div>
