import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { ICart } from "@app-components/shopping-cart/interfaces/shopping-cart.interface";
import { Subject } from "rxjs";
import { IProduct } from "../interfaces/product.interface";



@Injectable({
  providedIn: 'root'
})
export class CartService {

  products: IProduct[] = [];

  cart: ICart = {
    total: 0,
    subTotal: 0,
    products: this.products
  };
  // Para gestionar los productos con las notificaciones cuando se realizan acciones como borrar
  public itemsVar = new Subject<ICart>();
  public itemVar$ = this.itemsVar.asObservable();

  // Para gestionar la catidad del producto cuando se cambia en el carrito
  public itemsCart = new Subject<any>();
  public itemCart$ = this.itemsCart.asObservable();

  constructor(private router: Router) {

  }

  /* Inicializar el carrito de compra para tener la información almacenada */
  initialize() {
    const storeData: ICart = JSON.parse(localStorage.getItem('cart'));

    if (storeData !== null) {
      this.cart = storeData;
    }

    return this.cart;
  }

  public updateItemsInCart(newValue: ICart) {
    console.log(`actualizamos los items ${newValue.products}`);

    this.itemsVar.next(newValue);
  }

  manageProduct(product: IProduct) {
    // Obtener cantidad de productos en el carrito
    let productTotal = this.cart.products.length;
    console.log(`Entramos en manageProduct`);

    // Comprobamos si tenemos productos
    if (productTotal === 0) {

      this.cart.products.push(product);
    } else { // Si tenemos productos hacer lo siguiente
      let actionUpdateOk = false;

      for (let i: number = 0; i < productTotal; i++) {
        // Comprobar que coincide el producto con alguno de la lista
        if (product._id === this.cart.products[i]._id) {

          console.log('producto existente y vamos a gestionarlo');
          if (product.quantity === 0) {

            console.log('Borrar item seleccionado');

            // Quitar elemento
            this.cart.products = this.removeItemFromArr(this.cart.products, product);
            console.log(`${this.cart.products.length}`);


          } else { // Actualizar con la nueva información

             console.log(`Actualizamos el carrito`);

            this.cart.products[i] = product
          }

          actionUpdateOk = true;
          i = productTotal;
        }
      }

      if (!actionUpdateOk) {

        this.cart.products.push(product);
      }
    }

    this.checkoutTotal();
  }

  // Añadir información final antes de hacer el pedido
  checkoutTotal() {
    let subTotal = 0;
    let total = 0;
    this.cart.products.map((product: any) => {

      subTotal += product.quantity // subTotal = subTotal + product.quantity
      console.log(`producto del carrito ${product._id}, cantidad del producto ${product.quantity}`);

      total += (product.quantity * product.price);
    });

    this.cart.total = total;
    this.cart.subTotal = subTotal;
    console.log(this.cart, 'calculando');
    this.setInfo()
  }

  clear() {
    this.products = [];
    this.cart = {
      total: 0,
      subTotal: 0,
      products: this.products
    }
    this.setInfo();
    console.log('hemos borrado la información del carrito');

    return this.cart;
  }

  private setInfo() {

    console.log(`Seteamos al localstorage el carrito: ${this.cart}`);

    localStorage.setItem('cart', JSON.stringify(this.cart));
    this.updateItemsInCart(this.cart)
  }

  open() {
    document.getElementById("mySidenav").style.width = "600px";
    document.getElementById("overlay").style.display = 'block';
    document.getElementById("app").style.overflow = 'hidden';
    const elementMenu: Element = document.querySelector('#mySidenav');
    elementMenu.classList.add('open-sidenav');

  }

  close() {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("overlay").style.display = 'none';
    document.getElementById("app").style.overflow = 'auto';
    const elementMenu: Element = document.querySelector('#mySidenav');
    elementMenu.classList.remove('open-sidenav');

  }

  removeItemFromArr(arr, item) {
    return arr.filter( (e) => e !== item);
  };
}
